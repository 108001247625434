<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-row>
      <v-col />
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :item-class="setRowByDate"
      :options.sync="options"
      :server-items-length="totalItems"
      class="elevation-1 "
      stle
      :loading-text="$t('table.messages.loading')"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 30, 50],
        showFirstLastPage: false,
        'items-per-page-text': $t('table.messages.items_per_page'),
      }"
    >
      <template slot="no-data">
        {{ $t('table.messages.no_data') }}
      </template>
      <template
        v-slot:footer.page-text=""
        class="mr-0"
      >
        {{ $t('table.messages.page') }}
        {{ options.page }}
      </template>
      <template v-slot:top>
        <v-container class="mt-5">
          <v-row>
            <v-col class="pb-0">
              <div>
                <span class="forth--text text-h4 font-weight-bold ">{{
                  $t('messages.search_parttimer_list_text')
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex ">
            <v-col cols="10">
              <div class="align-self-center mr-9">
                <v-text-field
                  id="search"
                  v-model="search"
                  name="search pa-0 "
                  outlined
                />
              </div>
            </v-col>

            <v-col
              cols="2"
              class="d-flex align-end p-0"
            >
              <div class="mb-9">
                <v-btn
                  large
                  color="primary"
                  @click="onSearch"
                >
                  <span class="pr-5 pl-5 font-weight-bold text-h5">
                    {{ $t('table.messages.search') }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col
              cols="2"
              class="mr-auto"
            >
              <v-btn
                dense
                color="forth"
                class="mb-5 pt-0 pl-10 pr-10 white--text"
                @click="$router.push({path: '/parttimer-rice'})"
              >
                <span class="font-weight-bold text-h5">
                  {{ $t('messages.Create') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.index="props">
        {{ getIndex(props.index) }}
      </template>
      <template v-slot:item.sex="props">
        {{
          props.item.sex == 1
            ? $t('messages.male')
            : props.item.sex == 0
              ? $t('messages.female')
              : ''
        }}
      </template>
      <template v-slot:item.name="props">
        <a @click="$router.push('/parttimer-detail-rice/' + props.item.id)">
          {{ props.item.name }}
        </a>
      </template>
      <template v-slot:item.residenceStatus="props">
        <span>学生</span>
      </template>
      <template v-slot:item.status="props">
        <span class="colorActive">在職</span>
      </template>
      <template v-slot:footer.prepend />
    </v-data-table>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'
  export default {
    name: 'ParttimeList',
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        isSelectingFile: true,
        fileIsValid: true,
        selected: {},
        singleSelect: true,
        options: {},
        accounts: [],
        headers: [],
        editedIndex: -1,
        items: [],
        search: '',
        loading: false,
        totalItems: 1,
      }
    },
    computed: {
      ...get('parttimer', ['list', 'message', 'status', 'error', 'totalCount']),
      schoolList: get('colleague@list'),
    },
    watch: {
      error (value) {
        this.showSnackBar(value)
      },
      list (value) {
        const data = [...value.parttimeEmployees]
        data.forEach(employee => {
          const studentCardValidDateLeft =
            -1 * moment().diff(moment(employee.campusCardValidDate), 'days')
          const residenceCardValidDateLeft =
            -1 * moment().diff(moment(employee.residenceCardValidDate), 'days')
          employee.residenceCardValidDateLeft = residenceCardValidDateLeft
          employee.studentCardValidDateLeft = studentCardValidDateLeft
        })
        this.$set(this, 'items', data)
      },
      options: {
        handler () {
          this.initialize()
          if (this.pageOld !== 0) {
            if (this.options.page > this.pageOld && this.options.page !== 1) {
              this.totalStt += this.items.length
            } else if (this.options.page < this.pageOld && this.options.page !== 1) {
              this.totalStt -= this.options.itemsPerPage
            } else {
              this.totalStt = 0
            }
          }
          this.pageOld = this.options.page
        },
        deep: true,
      },
      search (value) {
        this.initialize(value)
      },
      status (value) {
        this.loading = value === 'loading'
      },
      totalCount (value) {
        this.totalItems = value
      },
    },
    mounted () {
      this.$store.dispatch('colleague/getColleagues')
      if (this.$route.query) {
        if (this.$route.query.success) this.showSnackBar('messages.success')
        if (this.$route.query.updateSuccess) this.showSnackBar('messages.updateSuccess')
      }
      // setting table's headers
      this.headers = [
        {
          text: this.$t('table.headers.index'),
          align: 'start',
          value: 'index',
          class: 'font-weight-bold',
          width: '5rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.manageId'),
          align: 'start',
          value: 'manageId',
          class: 'font-weight-bold',
          width: '7rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.name'),
          align: 'start',
          value: 'name',
          class: 'font-weight-bold',
          width: '11rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.furiganaName'),
          align: 'start',
          value: 'furiganaName',
          class: 'font-weight-bold',
          width: '11rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.sex'),
          align: 'start',
          value: 'sex',
          class: 'font-weight-bold',
          width: '4rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.language'),
          align: 'start',
          value: 'language',
          class: 'font-weight-bold',
          width: '6rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.visaId'),
          align: 'start',
          value: 'residenceStatus',
          class: 'font-weight-bold',
          width: '10rem',
          sortable: false,
        },

        {
          text: this.$t('table.headers.status'),
          align: 'start',
          value: 'status',
          class: 'font-weight-bold',
          width: '6rem',
          sortable: false,
        },
      ]
      this.$store.dispatch('parttimer/getNewPass', '')
    },
    methods: {
      OnclickCSV () {},

      initialize (search = '') {
        const tmpOptions = { ...this.options }
        tmpOptions.limit = tmpOptions.itemsPerPage
        tmpOptions.offset = (tmpOptions.page - 1) * tmpOptions.limit

        if (search) {
          tmpOptions.keyword = search
        }

        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('parttimer/getParttimers', tmpOptions)
      },

      highlightClickedRow (event) {
        const tr = event.target.parentNode
        tr.classList.add('highlight')
      },
      toEvaluation (item) {
        this.$router.push('/employee-eval/' + item.user_id)
      },
      onSearch () {
        this.initialize(this.search)
      },
      setRowByDate: function (item) {
        const studentCarddateLeft = parseInt(item.studentCardValidDateLeft)
        const residenceCarddateLeft = parseInt(item.residenceCardValidDateLeft)
        if (studentCarddateLeft <= 0 || residenceCarddateLeft <= 0) {
          return 'error--text'
        } else if (studentCarddateLeft <= 30 || residenceCarddateLeft <= 30) {
          return 'warning--text'
        }
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
    },
  }
</script>
<style lang="css">
.v-data-table {
  max-width: 100%;
}
.colorActive {
  color: #1bc4bd;
}
</style>
